<template>
  <b-card-code no-body title="All Roles">
    <button
      v-if="this.$store.state.app.user.permissions.includes('auth.add_group')"
      @click="addRole()"
      type="button"
      class="btn ml-auto btn-primary m-1"
    >
      Add New
    </button>

    <b-table
      responsive="true"
      id="orgTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(actions)="data">
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editRole(data.item.id)"
            >Edit</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item @click="deleteRole(data.item.id)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    />
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        // { key: 'id', label: '#' },
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/groups",
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        //   res.data.map(function(value, key) {
        //     // console.log(res.data[key].profile.organization)
        //      if(res.data[key].profile.organization != null){
        //         res.data[key].profile.organization = res.data[key].profile.o_profile.org_name
        //      }
        //   });
        this.items = res.data;
        console.log(this.items);
        this.rows = res.data.length;
      });
    },
    addRole() {
      this.$router.push({ name: "Add Role" });
    },
    editRole(id) {
      this.$router.push({ name: "Edit Role", params: { id: id } });
    },
    deleteRole(id) {
      if (confirm("Do you really want to delete?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "user/groups/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
          //if(res.data.org_id){
          // this.$router.push({name: 'Organizations'});
          // }
        });
      }
    },
  },
};
</script>